import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../user/user.service';
import {UiService} from '../shared/ui.service';
import {QuotingService} from '../quoting/quoting.service';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form = new FormGroup({
    agentNumber: new FormControl('', Validators.required),
    userName: new FormControl(''),
    password: new FormControl('', Validators.required)
  });
  constructor(private userSvc: UserService, private uiSvc: UiService, private router: Router, private activatedRoute: ActivatedRoute, private quoteSvc: QuotingService) { }

  ngOnInit(): void {
    UiService.ShowChat(false);
    localStorage.clear();

    const checkParams = (params: {[key: string]: any}) => {
      const token = params['token'] ?? params['id'];
      const quote = params['quote'] ?? params['quoteNumber'];
      const resource = params['resourceId'] ?? params['resources'];
      if (token) {
        if (quote) {
          this.loginAndLoadQuote(token, quote);
        } else if (resource) {
            this.loginAndLoadResource(token, resource);
        } else {
          this.loginWithToken(token);
        }
      }
    };

    this.activatedRoute.params.subscribe(checkParams);
    this.activatedRoute.queryParams.subscribe(checkParams);
  }

  async login() {
    this.uiSvc.dim('Logging In', 'Please wait while you\'re logged in');
    try {
      await this.userSvc.login(this.form.value.agentNumber, this.form.value.userName, this.form.value.password);
    } catch (e) {
      this.uiSvc.error('Error Logging In', e);
    }
    this.uiSvc.undim();
    this.router.navigate(['/quoting']);
  }

  async loginWithToken(token: string) {
    this.uiSvc.dim('Logging In', 'Please wait while you\'re logged in');
    try {
      await this.userSvc.loginWithToken(token);
    } catch (e) {
      this.uiSvc.error('Error Logging In', e);
    }
    this.uiSvc.undim();
    this.router.navigate(['/quoting']);
  }

  async loginAndLoadQuote(token: string, quote: string) {
    try {
      this.uiSvc.dim('Logging In', 'Please wait while you\'re logged');
      await this.userSvc.loginWithToken(token);
      await this.quoteSvc.readAndLoadQuote(quote);
      this.uiSvc.undim();
      const currentPage = this.quoteSvc.pages.value.find(p => p.current);
      this.router.navigate([`/quoting/${QuotingService.quote?.quoteNumber}/${ currentPage?.name ?? this.quoteSvc.pages.value[0].name}`]);
    } catch (e) {
      this.uiSvc.undim();
      this.uiSvc.error('Error Logging In & Loading the Quote', e);
    }
  }

  async loginAndLoadResource(token: string, resource: string) {
    try {
      this.uiSvc.dim('Logging In', 'Please wait while you\'re logged');
      await this.userSvc.loginWithToken(token);
      this.uiSvc.undim();
      this.router.navigate([`resources/${resource}`]);
    } catch (e) {
      this.uiSvc.undim();
      this.uiSvc.error('Error Logging In & Loading the Resource', e);
    }
  }

}
